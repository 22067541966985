@import 'variables';
@import 'mixins';
@import 'forms';
@import 'navbar';

$marketplace-sidebar-width: 300px;
$marketplace-sidebar-width-md: 250px;
$marketplace-sidebar-xs-closed-height: 105px;

.marketplace-sidebar {
  width: $marketplace-sidebar-width - 30px;
  position: fixed;
  top: $navbar-height;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: $color-sidebar;
  padding: 20px 25px 0;
  overflow: scroll;

  @media (max-width: $media-lg) {
    position: relative;
    width: 100%;
    top: 0;
    background: #fff;
  }

  &.m-box-shadow {
    @include box-shadow();
  }

  &__top {
    &-area {
      color: $color-dark;

      &:hover {
        cursor: pointer;
        color: $color-main;
      }

      @media (max-width: $media-md) {
        margin-top: 10px;
        margin-bottom: 40px;
      }
    }
  }

  &__categories {
    &-category {
      position: relative;

      &.m-active &-name {
        font-weight: 600;
      }

      &.m-active &-name-bg {
        display: block;
      }

      &.m-active &-name-icon {
        margin-right: 10px;
      }

      &-name {
        position: relative;

        &:hover {
          color: $color-main-darker;
        }

        &-icon {
          height: 33px;
          margin-right: 5px;
          position: relative;
          top: -5px;
        }

        &-bg {
          position: absolute;
          top: -6px;
          left: -8px;
          display: none;
        }
      }

      &-arrow {
        position: absolute;
        right: 15px;
        top: 5px;
      }

      &-sub-category {
        position: relative;
        padding-left: 20px;

        @media (max-width: $media-lg) {
          padding-left: 0;
        }

        &:hover &-name {
          color: $color-main-darker;
        }

        &.m-active &-name {
          color: $color-main-darker;
        }

        &-image {
          width: 90px;
          display: inline-block;

          @media (min-width: $media-xs) {
            width: 100px;
          }
        }

        &-name {
          position: relative;
        }
      }
    }
  }
}

.sidebar-content {
  padding-left: $marketplace-sidebar-width + 15px;
  padding-right: 30px;
  min-height: 100vh;
  overflow: hidden;
  padding-top: 30px;

  @media (max-width: $media-lg) {
    padding-left: 15px;
    padding-right: 15px;
    min-height: 0;
  }
}