@import 'variables';

.content-page {
  position: relative;
  padding-top: 50px;
  overflow: hidden;

  .img-content {
    max-width: 300px;
  }

  &__circle {
    &-green {
      position: absolute;
      width: 200px;
      left: -10px;

      @media (max-width: $media-xl) {
        left: -220px;
      }

      @media (max-width: $media-lg) {
        left: -280px;
      }

      @media (max-width: $media-md) {
        width: 300px;
        left: -230px;
        top: 300px;
      }
      @media (max-width: $media-sm) {
        display: none;
      }
    }

    &-purple {
      position: absolute;
      width: 200px;
      right: 0;
      top: 500px;

      @media (max-width: $media-lg) {
        right: -80px;
      }

      @media (max-width: $media-md) {
        right: -150px;
        top: 700px;
      }

      @media (max-width: $media-sm) {
        display: none;
      }
    }
  }

  &__container {
    padding-bottom: 50px;
    min-height: 500px;
  }

  &__app-links {
    img {
      width: 200px;
      margin-bottom: 10px;
    }
  }
}