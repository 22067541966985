.m-b-0 {
	margin-bottom: 0 !important;
}

.m-b-1 {
	margin-bottom: 5px !important;
}

.m-b-2 {
	margin-bottom: 10px !important;
}

.m-b-3 {
	margin-bottom: 15px !important;
}

.m-b-4 {
	margin-bottom: 20px !important;
}

.m-b-5 {
	margin-bottom: 25px !important;
}

.m-b-6 {
	margin-bottom: 30px !important;
}

.m-t-0 {
	margin-top: 0 !important;
}

.m-t-1 {
	margin-top: 5px !important;
}

.m-t-2 {
	margin-top: 10px !important;
}

.m-t-3 {
	margin-top: 15px !important;
}

.m-t-4 {
	margin-top: 20px !important;
}

.m-t-5 {
	margin-top: 25px !important;
}

.m-t-6 {
	margin-top: 30px !important;
}

.m-l-0 {
	margin-left: 0 !important;
}

.m-l-1 {
	margin-left: 5px !important;
}

.m-l-2 {
	margin-left: 10px !important;
}

.m-l-3 {
	margin-left: 15px !important;
}

.m-l-4 {
	margin-left: 20px !important;
}

.m-l-5 {
	margin-left: 25px !important;
}

.m-l-6 {
	margin-left: 30px !important;
}

.m-r-1 {
	margin-right: 5px !important;
}

.m-r-2 {
	margin-right: 10px !important;
}

.m-r-3 {
	margin-right: 15px !important;
}

.m-r-4 {
	margin-right: 20px !important;
}

.m-r-5 {
	margin-right: 25px !important;
}

.m-r-6 {
	margin-right: 30px !important;
}

.p-a-0 {
	padding: 0 !important;
}

.p-a-1 {
	padding: 5px !important;
}

.p-a-2 {
	padding: 10px !important;
}
.p-a-3 {
	padding: 15px !important;
}
.p-a-4 {
	padding: 20px !important;
}
.p-a-5 {
	padding: 25px !important;
}
.p-a-6 {
	padding: 30px !important;
}

.p-b-0 {
	padding-bottom: 0 !important;
}

.p-b-1 {
	padding-bottom: 5px !important;
}

.p-b-2 {
	padding-bottom: 10px !important;
}

.p-b-3 {
	padding-bottom: 15px !important;
}

.p-b-4 {
	padding-bottom: 20px !important;
}

.p-b-5 {
	padding-bottom: 25px !important;
}

.p-b-6 {
	padding-bottom: 30px !important;
}

.p-t-0 {
	padding-top: 0 !important;
}

.p-t-1 {
	padding-top: 5px !important;
}

.p-t-2 {
	padding-top: 10px !important;
}

.p-t-3 {
	padding-top: 15px !important;
}

.p-t-4 {
	padding-top: 20px !important;
}

.p-t-5 {
	padding-top: 25px !important;
}

.p-t-6 {
	padding-top: 30px !important;
}

.p-l-1 {
	padding-left: 5px !important;
}

.p-r-1 {
	padding-right: 5px !important;
}

.p-l-0 {
	padding-left: 0 !important;
}

.p-r-0 {
	padding-right: 0 !important;
}