@import 'variables';
@import 'mixins';

.item-view, .item-bottom {
  padding: 30px 15px 30px 15px;

  @media (max-width: $media-lg) {
    padding: 30px 15px;
  }

  @media (max-width: $media-md) {
    padding: 15px;
  }
}

.item-view {

  &__images {
    &-image {
      width: 100%;
      overflow: hidden;
      @include border-radius();
      background: #ffffff;

      img {
        max-height: 100%;
        max-width: 100%;
        margin: 0 auto;
        display: block;
      }
    }

    &-thumbs {
      float: left;

      &-thumb {
        border: 1px solid #fff;
        @include border-radius();
        width: 88px;
        height: 88px;
        overflow: hidden;
        float: left;
        margin-right: 5px;
        margin-top: 5px;

        @media (max-width: $media-lg) {
          width: 71px;
          height: 71px;
        }

        @media (max-width: $media-sm) {
          width: 63px;
          height: 63px;
        }

        &.m-active {
          border-color: $color-main-darker;
        }

        &:hover {
          border-color: $color-dark;
          cursor: pointer;
        }

        img {
          height: 100%;
        }
      }
    }
  }

  &__details {
    &-marketplace {
      font-weight: 600;
    }

    &-button {
      width: 200px;
    }

    &-ingredients {
      background: $color-main-new-bg;
      @include border-radius();
    }

    &-price {
      color: $color-main;

      @media (max-width: $media-sm) {
        font-size: 23px;
      }
    }

    &-links {
      &-link {
        color: $color-muted;
        margin-right: 15px !important;
        display: inline-block;

        &.m-active {
          color: #000;
        }

        &:hover {
          cursor: pointer;
          color: $color-main-darker;
        }
      }
    }

    &-marketplaces {
      &-header {
        small {
          font-weight: 600;
        }
      }
    }
  }

  &__user {
    color: $color-dark;

    &:hover &-avatar {
      border-color: $color-dark;
    }

    &:hover {
      cursor: pointer;
      color: $color-main;
    }

    &-avatar {
      width: 30px;
      height: 30px;
      border: 1px dotted transparent;
      @include border-radius(50%);
      margin-right: 5px;
    }
  }
}

.item-bottom {
  background: $color-main-new-bg;
  min-height: 200px;

  &__marketplace {
     &-name {
       &:hover {
         color: $color-main-darker;
       }
    }
    &-image {
      height: 150px;
      @include background-image();
      @include border-radius();
    }
  }
}

.item-view-page {
  padding: 0 60px;

  @media (max-width: $media-sm) {
    padding: 0 15px;
  }
}