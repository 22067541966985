@import 'variables';
@import 'mixins';
@import 'forms';

.marketplace-view-item-col {
  padding: 0 45px;

  @media (max-width: $media-xl) {
    padding: 0 15px;
  }

  &:nth-child(odd) {
    @media (max-width: $media-md) {
      padding-right: 7.5px;
    }

    @media (max-width: $media-sm) {
      padding-right: 5px;
    }
  }
  &:nth-child(even) {
    @media (max-width: $media-md) {
      padding-left: 7.5px;
    }

    @media (max-width: $media-sm) {
      padding-left: 5px;
    }
  }
}

.marketplace-view-item-modal-container {
  @media (max-width: $media-sm) {
    padding: 0;
  }
}

.marketplace-view-item {
  @include border-radius(20px);
  background: #ffffff;
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;

  &:hover {
    cursor: pointer;
  }

  &:hover &__date {
    opacity: 1;
  }

  &:hover &__content-name {
    color: $color-main-darker;
  }

  &__image, &__no-image {
    position: relative;
    background: darken(#fff, 2);
    height: 250px;

    &-discount {
      background: $color-discount;
      color: #fff;
      position: absolute;
      top: 0;
      right: 0;
      padding: 5px 20px;
      border-bottom-left-radius: 20px;
    }

    &-availability {
      background: rgba(250,250,250,0.9);
      position: absolute;
      top: 0;
      right: 0;
      padding: 5px 20px;
      border-bottom-left-radius: 20px;
    }

    &.m-small {
      height: 210px;
    }

    @media (max-width: $media-lg) {
      height: 220px;
    }

    @media (max-width: $media-sm) {
      height: 180px;
    }

    @media (max-width: $media-xs) {
      height: 210px;
    }
  }

  &__image {
    @include background-image();
    @include border-radius(20px);
    overflow: hidden;
  }

  &__content {
    &-producer {
      font-size: 13px;
    }

    &-name {
      height: 47px;
      overflow: hidden;
      white-space: pre-wrap;

      @media (max-width: $media-md) {
        margin-bottom: 10px;
      }
    }

    &-cart {
      text-align: center;
    }

    &-price {
      @include text-ellipsis();
    }

    &-address {
      width: 40%;
      float: left;
      padding-right: 0;
      height: $text-height;
      text-align: left;
    }
  }

  &__date {
    position: absolute;
    width: 120px;
    height: 120px;
    top: -60px;
    left: -60px;
    @include border-radius(50%);
    border: 1px dotted $color-dark;
    background: #fff;
    padding: 69px 0 0 49px;
    opacity: 0;
    @include transition(opacity 0.1s);
    line-height: 13px;

    @media (max-width: $media-lg) {
      opacity: 1;
      width: 100px;
      height: 100px;
      top: -50px;
      left: -50px;
      padding: 55px 0 0 37px;
    }
  }
}