@import 'variables';

.facebook-register-button {
	border-radius: 22.5px;
	height: 45px;
	width: 100%;
	background: #3b5998;
	color: #fff;
	text-align: center;
	display: inline-block;
	position: relative;
	padding: 12px 10px 0 20px;

	&:hover {
		background: #3c64a4;
		cursor: pointer;
	}

	&:focus, &:active {
		background: #38508c;
	}

	img {
		margin-right: 2px;
		width: 18px;
	}
}

.login-signup {
	margin-top: 100px;
	margin-bottom: 100px;
}

.login-modal {

	&__alternative, &__alternative:focus {
		color: $color-main;
		font-weight: 600;
	}

	&__alternative:hover {
	}
}