@import 'variables';
@import 'mixins';
@import 'typography';

$mobile-back-button-height: 60px;

.shop-items {

  @media (max-width: $media-lg) {
    padding-bottom: $mobile-back-button-height + 15;
  }

  &__categories {
    position: relative;

    &-category {
      &:hover &-name, &.m-active &-name {
        color: $color-main-darker;
      }

      img, &-icon {
        background: $color-main-new-bg;
        width: 100px;
        height: 100px;
        display: inline-block;
        @include background-image();
        @include border-radius();

        @media (max-width: $media-sm) {
          width: 75px;
          height: 75px;
        }
      }

      &-name {}
    }
  }
  
  &__second-level-back-button {
    height: $mobile-back-button-height;
    background: #DEB183;
    left: 0;
    right: 0;
    position: fixed;
    bottom: 0;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    padding: 21px 0;
  }
}

.shop-items-index {
  &__categories {
    &-category {
      &-icon {
        width: 50px;
        height: 50px;
      }
    }
  }
}

.first-level-category {
  &:hover &__name, &.m-active &__name {
    color: $color-main-darker;
  }

  &.m-small &__icon {
    width: 75px;
    height: 75px;
  }

  &__image {
    width: 100px;
    height: 100px;
    display: inline-block;
  }
}