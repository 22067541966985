// Title font:
@font-face {
  font-family: 'Source Serif Pro';
  src:  url('./assets/fonts/source-serif-pro-v15-latin-700.woff2') format('woff2'),
  url('./assets/fonts/source-serif-pro-v15-latin-700.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Source Serif Pro';
  src:  url('./assets/fonts/source-serif-pro-v15-latin-700italic.woff2') format('woff2'),
  url('./assets/fonts/source-serif-pro-v15-latin-700italic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

// Regular font:
@font-face {
  font-family: 'Metropolis';
  src:  url('./assets/fonts/Metropolis-Medium.woff2') format('woff2'),
        url('./assets/fonts/Metropolis-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Metropolis';
  src:  url('./assets/fonts/Metropolis-MediumItalic.woff2') format('woff2'),
        url('./assets/fonts/Metropolis-MediumItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Metropolis';
  src:  url('./assets/fonts/Metropolis-SemiBold.woff2') format('woff2'),
        url('./assets/fonts/Metropolis-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Metropolis';
  src:  url('./assets/fonts/Metropolis-SemiBoldItalic.woff2') format('woff2'),
        url('./assets/fonts/Metropolis-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}


@font-face {
  font-family: 'Metropolis';
  src:  url('./assets/fonts/Metropolis-Bold.woff2') format('woff2'),
        url('./assets/fonts/Metropolis-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Metropolis';
  src:  url('./assets/fonts/Metropolis-BoldItalic.woff2') format('woff2'),
        url('./assets/fonts/Metropolis-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

