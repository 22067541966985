@import 'variables';
@import 'mixins';

.b-modal {
  background: #fff;
  padding: 50px 50px 75px 50px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  min-height: 300px;

  &.m-overflow-visible {
    overflow: visible;
  }

  &__top {
    color: #fff;
    padding: 15px 0;
  }

  @media (max-width: $media-lg) {
    padding: 50px 25px 75px 25px;
  }

  @media (max-width: $media-sm) {
    padding: 50px 15px 75px 15px;
  }

  &.m-no-padding {
    padding: 0;
  }
}

.b-modal-close {
  position: absolute;
  color: $color-dark;
  top: 20px;
  right: 25px;
  z-index: 9999;
  background: #fff;
  @include border-radius(50%);
  width: 30px;
  height: 30px;
  padding-top: 2.5px;
  padding-left: 6.5px;

  @media (max-width: $media-sm) {
    padding-top: 4px;
    padding-left: 7.5px;
    right: 20px;
  }

  svg {
    font-size: 25px;

    @media (max-width: $media-sm) {
      font-size: 22px;
    }
  }

  &:hover {
    cursor: pointer;
    color: $color-main;
  }
}

.b-modal-next, .b-modal-previous {
  position: absolute;
  top: 0;
  color: #fff;
  font-size: 30px;
  height: 100vh;
  width: 50px;
  text-align: center;
  z-index: 2;

  @media (max-width: $media-md) {
    height: auto;
    top: -36px;
    font-size: 20px;
  }

  @media (max-width: $media-md) {
    color: $color-main;
    top: 55%;
  }

  svg {
    position: relative;
    top: 35%;
  }

  &:hover {
    cursor: pointer;
    color: $color-main;
  }
}

.b-modal-next {
  right: -50px;

  @media (max-width: $media-lg) {
    right: -40px;
  }

  @media (max-width: $media-md) {
    right: -15px;
  }

  i {
    left: 26px;
  }
}

.b-modal-previous {
  left: -50px;

  @media (max-width: $media-lg) {
    left: -40px;
  }

  @media (max-width: $media-md) {
    left: -15px;
  }

  i {
    left: 4px;
  }
}

.modal-login {
  width: 600px;

  @media (max-width: $media-lg) {
    width: 500px;
  }

  @media (max-width: $media-md) {
    width: auto;
  }
}

.modal-dialog {
  margin-top: 50px;
  margin-bottom: 20px;

  &.container-size {
    max-width: 90%;
    margin: 40px auto 20px;

    @media (max-width: $media-md) {
      max-width: 95%;
    }
  }

  @media (max-width: $media-md) {
    margin-top: 8px;
    margin-bottom: 50px;
  }

  .modal-content {
    @include border-radius();
    @include box-shadow(none);
    background: none;
    border: none;
  }
}
