@import 'variables';
@import 'mixins';

$navbar-height-landing: 135px;
$navbar-height: 90px;
$navbar-height-xs: 80px;

.navbar {
  height: $navbar-height;
  position: fixed;
  z-index: 3;
  top: 0;
  width: 100%;
  padding: 0 50px;
  background: #fff;
  box-shadow: 0px -2px 20px -2px rgba(221, 221, 221, 1);

  &.m-landing {
    position: absolute;
    box-shadow: none;
    background: transparent;

    @media (max-width: $media-md) {
      height: $navbar-height-xs;
      overflow: hidden;
    }
  }

  @media (max-width: $media-lg) {
    height: $navbar-height-xs;
    overflow: visible;

    &.m-show-xs-nav {
      background: #fff;
      position: fixed;
    }
  }

  @media (max-width: $media-sm) {
    padding: 0 10px;
  }

  &__left {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &-search {
      width: 200px;

      @media (max-width: $media-xl) {
        width: 150px;
      }

      @media (max-width: $media-lg) {
        width: 130px;
      }

      @media (max-width: $media-sm) {
        width: auto;
      }
    }
  }

  &__logo {
    img {
      width: 150px;

      @media (max-width: $media-lg) {
        width: 100px;
      }

      @media (max-width: $media-sm) {
        width: 45px;
      }

      @media (max-width: 340px) {
        width: 35px;
      }
    }

    position: relative;
  }

  &__right {
    float: right;
    margin: 0;
    position: relative;

    &-link {
      position: relative;
      margin-right: 0 !important;
      float: left;

      &-element, &-toggle {
        color: #000;
        display: block;
        height: 100%;
        padding: 30px 15px 30px;
        font-weight: 600;

        &.btn-normal-link {
          font-weight: 600 !important;
        }

        @media (max-width: $media-xl) {
          padding: 30px 7px 30px;
        }

        @media (max-width: $media-lg) {
          padding: 30px 10px 30px;
        }

        &:hover, &.active {
          color: $color-main;
        }
      }

      &.m-with-avatar &-link {
        padding: 22px 25px 12px;
      }

      &-avatar {
        width: 50px;
        height: 50px;
        @include border-radius(50%);
        margin-right: 5px;
        position: relative;
        top: -5px;
      }

      &-initial {
        width: 50px;
        height: 50px;
        @include border-radius(50%);
        margin-right: 5px;
        position: relative;
        top: -5px;
        padding-top: 10px;
        border: 2px solid #ddd;
      }

      &-username {
        display: inline-block;
        max-width: 124px;
        height: $text-height;
        white-space: nowrap;
        overflow: hidden;
        -ms-text-overflow: ellipsis;
        text-overflow: ellipsis;
        position: relative;
        margin-left: 3px;
      }

      &-caret {
        font-size: 14px;
        margin-left: 7px;
        position: relative;
        top: -6px;
      }

      &-toggle {
        margin-left: 5px;
      }

      .navbar-button-link {
        margin: 25px 20px 22px 20px;

        @media (max-width: $media-sm) {
          margin: 25px 5px 22px 5px;
        }

        &.m-normal-button {
          margin: 0;
          padding: 30px 15px 31px 15px;

          @media (max-width: $media-xl) {
            padding: 30px 7px 31px 10px;
          }
        }
      }

      .btn.btn-small {
        @media (max-width: $media-lg) {
          padding-left: 15px;
          padding-right: 15px;
        }

        @media (max-width: $media-md) {
          padding-left: 10px;
          padding-right: 10px;
        }
      }

      &.m-hover-cart-popup:hover &-popup.m-cart-popup,
      &.m-hover-cart-popup:focus &-popup.m-cart-popup {
        display: block;
      }

      &.m-hover-user-popup:hover &-popup.m-user-popup,
      &.m-hover-user-popup:focus &-popup.m-user-popup {
        display: block;
      }

      &.m-hover-info-popup:hover &-popup.m-info-popup,
      &.m-hover-info-popup:focus &-popup.m-info-popup {
        @media (min-width: $media-lg) {
          display: block;
        }
      }

      &.m-hover-info-popup.m-visible &-popup.m-info-popup {
        @media (max-width: $media-lg) {
          display: block;
        }
      }

      &-popup {
        z-index: 1;
        position: absolute;
        display: none;
        top: $navbar-height - 5;
        right: -27px;
        background: $color-gray;
        width: 300px;
        box-shadow: 0px 5px 5px 0px rgba(85, 85, 85, 0.2);
        border-bottom-right-radius: 2px;
        border-bottom-left-radius: 2px;
        padding: 20px;
        max-height: 80vh;
        overflow: scroll;

        &.m-cart-popup {
          width: 400px;
        }

        .m-popup-user-link {
          float: left;
          width: 100%;
          padding: 15px !important;
          color: $color-dark;
          text-align: left;
          font-weight: normal;

          &:hover, &.active {
            color: $color-main;
          }
        }

        &-arrow {
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 10px 15px 10px;
          border-color: transparent transparent #f2f2f2 transparent;
          position: absolute;
          top: -15px;
          left: 63%;
        }

        &-user {
          position: relative;
          padding: 10px 10px 0;
          margin-bottom: 10px;

          &-image {
            position: absolute;
            left: 15px;
            top: 20px;
            width: 50px;
            height: 50px;
            padding-top: 10px;
            @include border-radius(50%);
            border: 2px solid #ddd;
          }

          img {
            position: absolute;
            left: 15px;
            top: 20px;
            width: 50px;
            height: 50px;
            @include border-radius(50%);
          }

          &-content {
            padding-left: 60px;
            padding-top: 12px;
            padding-bottom: 20px;
            border-bottom: 1px dotted $color-dark;

            &-name {
              color: $color-dark;
            }

            &-email {
            }
          }
        }
      }

      &-cart-popup {
        &-item {
          &-control {
            position: relative;
            top: -1px;

            &:hover {
              cursor: pointer;
              color: $color-main;
            }
          }

          &-image {
            width: 20px;
            @include border-radius();
          }
        }
      }
    }

    &-toggle {
      float: right;
      color: #000;
      position: relative;
      top: 3px;

      button {
        -webkit-appearance: none;
        border: none;
        background: none;
        outline: none;
      }

      &:hover, &:active, &:focus {
        cursor: pointer;
      }
    }
  }
}

.navbar-xs {
  position: fixed;
  top: $navbar-height-xs;
  width: 100%;
  background: #fff;
  left: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 10;

  &.m-visible {
    position: fixed;
    left: 0;
  }

  &__links {
    ul {
      margin: 0;
    }

    &-link {
      text-align: center;
      padding: 0 30px;

      &:last-child a {
        border-bottom: none;
      }

      a, .btn.btn-normal-link {
        padding: 20px;
        display: block;
        color: #000;
        width: 100%;
        font-weight: normal;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}