.geosuggest {
  position: relative;

  &__suggests {
    &\--hidden {
      display: none;
    }

    list-style: none;
    padding: 0;
    border: 1px solid #ddd;
    border-top: none;
    position: absolute;
    top: 40px;
    width: 100%;
    background: #fff;
    z-index: 5;
  }

  &__item {
    cursor: pointer;
    padding: 10px;
    width: 100%;

    &:hover, &:focus {
      background: #eee;
    }

    &\--active {
      background: #267dc0;
      color: #fff;

      &:hover, &:focus {
        background: #ccc;
      }
    }
  }
}