@import 'variables';
@import 'mixins';

.panel {
  background: #fff;
  @include border-radius();
  overflow: hidden;
  padding: 15px 40px 0;
  position: relative;
  margin-bottom: 30px;

  @media (max-width: $media-md) {
    padding: 15px 15px 0;
  }

  &__corner {
    width: 100px;
    height: 100px;
    background: $color-dark;
    @include border-radius(50%);
    overflow: hidden;
    position: absolute;
    top: -50px;
    left: -50px;

    svg {
      color: #fff;
      position: absolute;
      bottom: 18px;
      right: 20px;
      font-size: 20px;
    }
  }

  &__title {
    padding-left: 30px;
    text-transform: uppercase;
    margin-bottom: 45px;

    @media (max-width: $media-md) {
      padding-left: 50px;
    }

  }
}