@import 'variables';
@import 'mixins';
@import 'forms';

.cart {
  &__item {
    @include box-shadow();
    @include border-radius();
    padding: 15px;

    @media (max-width: $media-md) {
      padding: 10px;
    }

    &-image {
      width: 50px;
      @include border-radius();
      margin-right: 5px;
    }

    &-control {
      &:hover {
        cursor: pointer;
        color: $color-main;
      }
    }

    &-buttons {
      @media (max-width: $media-md) {
        margin-top: 10px;
      }
    }
  }

  &__coupon {
    &-remove:hover {
      cursor: pointer;
    }
  }

  &__delivery {

    &-ranges {
      margin-bottom: 15px;

      @media (max-width: $media-md) {
        margin-bottom: 0;
      }

      &-range {
        display: inline-block;
        @include border-radius();
        padding: 10px 15px;
        border: 2px solid $color-gray;

        @media (max-width: $media-md) {
          margin-bottom: 10px;
        }

        &.m-active {
          border-color: $color-main-new;
        }

        &:hover:not(.m-disabled) {
          border-color: $color-main-new;
          cursor: pointer;
        }
      }
    }
  }
}

.delivery-day-marketplace-modal {

  &__choices {

    &-choice {
      @include border-radius();
      box-shadow: 0px 4px 15px -2px #dddddd;
      padding: 20px;

      &.m-active {
        color: $color-main;
      }

      &:hover {
        color: $color-main;
        cursor: pointer;
      }
    }
  }
}

.cart-button {
  position: relative;

  &__stock {
    position: absolute;
    top: -10px;
    right: 0;
    background: $color-discount;
    font-size: 12px;
    padding: 0 10px;
    @include border-radius();
  }
}

.cart-item-status {
  background: $color-main-new;
  height: $button-height;
  @include border-radius($button-height/2);
  position: relative;
  width: auto;
  display: inline-block;
  text-align: center;

  &.m-block {
    width: 100%;
  }

  &.m-neutral {
    background: $color-gray;
  }

  &.m-white {
    background: #fff;
  }

  &.m-small {
    height: $button-small-height;
    @include border-radius($button-small-height/2);
  }

  &__add, &__remove, &__quantity {
    position: relative;
    display: inline-block;
    color: $color-dark;
    top: 50%;
    @include transform(translateY(-50%));
  }

  &__add, &__remove {
    padding: 5px 10px;

    &:hover {
      cursor: pointer;
    }
  }

  &__quantity {
    padding: 6px 10px;
  }
}

.payment-method {
  @include border-radius();
  box-shadow: 0 4px 15px -2px #dddddd;
  padding: 20px;
  color: $color-dark;

  &:hover {
    cursor: pointer;
    color: $color-main;
  }

  img {
    width: 40px;
    margin-right: 10px;
  }
}

.payment-method-modal {
  &__choices {
    &-choice {
      position: relative;

      &-trash {
        position: relative;
        top: 25%;

        &:hover {
          color: $color-error;
          cursor: pointer;
        }
      }
    }
  }
}

.cart-address {
  @include border-radius();
  box-shadow: 0 4px 15px -2px #dddddd;
  padding: 20px;
  color: $color-dark;

  &:hover {
    cursor: pointer;
    color: $color-main;
  }

  &:hover &__title {
    color: $color-main;
  }
}