@import 'variables';
@import 'mixins';
@import 'typography';

.order-batch {
  @include border-radius();
  @include box-shadow();
  padding: 20px;
  background: #ffffff;

  &:hover &__title {
    color: $color-main;
  }
}

.order-batch-view {
  &__order {

    &-item {
      margin-bottom: 5px;
      @media (max-width: $media-sm) {
        margin-bottom: 10px;
      }

      &-quantity {
        @media (max-width: $media-sm) {
          font-size: $font-size-small !important;
        }
      }
    }
  }
}