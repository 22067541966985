@import 'variables';
@import 'mixins';
@import 'forms';

.marketplace-view {
  position: relative;
  padding-bottom: 30px;

  &__image {
    background: $color-gray;
    @include border-radius(20px);
    @include background-image();
    width: 100%;
    height: 220px;

    @media (max-width: $media-lg) {
      height: 200px;
    }

    @media (max-width: $media-md) {
      margin-bottom: 15px;
      height: 150px;
    }

    @media (min-width: $media-lg) {
      height: 150px;
    }

    @media (min-width: $media-xl-1) {
      height: 180px;
    }

    @media (min-width: $media-xl-2) {
      height: 220px;
    }
  }

  &__top {
    position: relative;

    &-image {
      position: absolute;
      left: 0;
      top: 0;
      width: 200px;
      height: 200px;
      background: $color-gray;
      @include border-radius(50%);
      @include background-image();
      display: inline-block;
    }

    &-content {
      padding-left: 220px;
    }
  }

  &__separator {
    border-bottom: 1px dotted $color-dark;
    padding-bottom: 30px;
    margin-bottom: 20px;
  }

  &__categories {

    &-category {
      color: $color-dark;
      margin-right: 30px;
      font-weight: 600 !important;

      &:hover, &.active {
        color: $color-main !important;
      }
    }
  }

  &__button {
    position: absolute;
    top: -5px;
    right: 50px;

    @media (max-width: $media-xl) {
      top: -65px;
      right: 0;
    }

    @media (max-width: $media-md) {
      right: 15px;
    }

    @media (max-width: $media-sm) {
      position: relative;
      top: auto;
      right: auto;
      text-align: center;
      margin-top: 20px;
    }
  }
}