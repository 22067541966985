@import 'variables';
@import 'mixins';

.cta-group-categories {
  position: relative;

  &__content {

    &.m-scroll-horizontally {
      @media (max-width: $media-lg) {
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        padding-right: 30px;
      }
    }

    &.m-scroll-horizontally &-category {
      width: 200px;

      @media (max-width: $media-md) {
        width: 120px;
      }
    }

    &-category {
      text-align: center;
      height: 133px;
      width: 200px;
      display: inline-block;
      padding: 15px 10px;
      @include border-radius(20px);
      font-weight: 600;
      white-space: normal;
      vertical-align:text-top;
      margin-bottom: 10px;

      @media (max-width: $media-md) {
        width: 30%;
      }

      @media (max-width: $media-sm) {
        width: 46.5%;
      }

      &-image {
        img {
          height: 50px;
        }
      }

      &-title {
        white-space: normal;
        height: 48px;
        overflow: hidden;
      }
    }
  }
}

.cta-group {

  &__title {
    img {
      width: 40px;
      margin-right: 5px;
      color: $color-dark;
    }
  }

  &__item {
    min-width: 210px;

    @media (max-width: $media-lg) {
      min-width: 200px;
    }
    @media (max-width: $media-md) {
      min-width: 0;
    }
  }

  &__cta {

    &-image {
      @include border-radius(20px);
      height: 150px;
      @include background-image();

      @media (max-width: $media-lg) {
        height: 140px;
      }
    }

    &:hover &-title {
      color: $color-main;
    }
  }

  &__marketplace {
    position: relative;

    &-top {
      position: relative;
      height: 100px;

      @media (max-width: $media-md) {
        height: 70px;
      }

      &-image {
        width: 100px;
        height: 100px;
        @include background-image();
        @include border-radius(20px);
        position: absolute;
        top: 0;
        left: 0;

        @media (max-width: $media-md) {
          width: 70px;
          height: 70px;
        }
      }

      &-content {
        padding-left: 120px;
        position: relative;
        @include vertically-centered();

        @media (max-width: $media-md) {
          padding-left: 80px;
        }
      }
    }
  }

  &__items {
    position: relative;

    &-item {
      width: 210px;
      padding-right: 30px;
      vertical-align:text-top;
      display: inline-block;

      @media (max-width: $media-lg) {
        width: 200px;
      }

      @media (max-width: $media-md) {
        width: 200px;
        white-space: normal;
      }

      @media (max-width: $media-sm) {
        width: 170px;
      }
    }
  }
}