@import 'variables';
@import 'mixins';

.landing {
  position: relative;
  background: $color-main-new-bg;

  &__second, &__third, &__fourth {
    padding-top: 130px;
    padding-bottom: 130px;

    @media (max-width: $media-lg) {
      padding-top: 80px;
      padding-bottom: 80px;
    }
  }

  &__first {
    background: $color-main-new;

    &.m-office {
      background: $color-new-pink;
    }

    &.m-gifts {
      background: $color-new-green;
    }

    &-left {
      position: relative;

      &-content {
        display: inline-block;
        max-width: 500px;

        @media (max-width: $media-xl) {
          max-width: 400px;
        }

        @media (max-width: $media-lg) {
          max-width: 350px;
        }

        @media (max-width: $media-md) {
          max-width: 340px;
          padding: 50px 0;
        }

        &-title {
          font-weight: 600;
          font-size: 45px;
          margin-bottom: 20px;

          @media (max-width: $media-xl) {
            font-size: 40px;
          }

          @media (max-width: $media-lg) {
            font-size: 30px;
            margin-bottom: 10px;
          }

          @media (max-width: $media-md) {
            font-size: 30px;
          }
        }

        &-description {
          font-size: 20px;
          margin-bottom: 30px;

          @media (max-width: $media-lg) {
            margin-bottom: 15px;
            font-size: 18px;
          }
        }
      }
    }

    &-right {
      background: url('./assets/fruits.jpg');
      @include background-image();

      &.m-office {
        background: url('./assets/business-landing.jpg');
        @include background-image();
      }

      height: 550px;

      @media (max-width: $media-xl) {
        height: 500px;
      }

      @media (max-width: $media-lg) {
        height: 450px;
      }

      @media (max-width: $media-md) {
        height: 250px;
      }
    }

    &-coupon {
      position: absolute;
      bottom: 0;
      right: 100px;
      width: 150px;

      @media (max-width: $media-xl) {
        right: 50px;
      }

      @media (max-width: $media-md) {
        width: 350px;
        left: 50%;
        right: auto;
        transform: translateX(-50%);
      }

      @media (max-width: $media-sm) {
        font-size: 14px;
        width: 90%;
      }
    }
  }

  &__apps {
    padding-top: 50px;
    padding-left: 15px;
    padding-right: 15px;
    background: #ffffff;
  }

  &__products {
    padding: 100px 0;
    background: #ffffff;
  }

  &__numbers {
    padding-top: 100px;
    padding-bottom: 100px;

    @media (max-width: $media-xl) {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    &-number {
      position: relative;
      height: 250px;
      @include background-image(contain);

      &-content {
        display: inline-block;
        position: relative;
        width: 200px;
        @include vertically-centered();

        @media (max-width: $media-lg) {
          width: 150px;
        }
      }

      @media (max-width: $media-lg) {
        height: 200px;
      }
    }
  }

  &__delivery {
    background: #F3E3D2;

    &-left {
      @include background-image();

      height: 850px;

      @media (max-width: $media-xl) {
        height: 600px;
      }

      @media (max-width: $media-lg) {
        height: 500px;
      }

      @media (max-width: $media-md) {
        height: 250px;
      }
    }

    &-right {

      &-content {
        display: inline-block;
        max-width: 500px;

        @media (max-width: $media-xl) {
          max-width: 400px;
        }

        @media (max-width: $media-lg) {
          max-width: 350px;
        }

        @media (max-width: $media-md) {
          max-width: 340px;
          padding: 50px 0;
        }
      }

      &-bolt-market {
        width: 200px;
      }
    }
  }

  &__banners {
    padding: 50px 0 0;
    position: relative;

    @media (max-width: $media-lg) {
      padding-bottom: 100px;
    }

    &-freshness {
      vertical-align: bottom;

      &:hover &-title {
        color: $color-main;
      }

      &-image {
        height: 180px;

        @media (max-width: $media-xl) {
          height: 150px;
        }

        @media (max-width: $media-lg) {
          height: 170px;
        }

        @media (max-width: $media-sm) {
          max-width: 100%;
          height: auto;
        }
      }
    }

    &-banner {
      @media (max-width: $media-sm) {
        padding: 0 20px;
      }

      &:hover &-title {
        color: $color-main;
      }

      &-image {
        @include border-radius(20px);
        background: $color-gray-light;
        @include background-image();
        height: 200px;
        position: relative;
        overflow: hidden;

        @media (max-width: $media-xl) {
          height: 150px;
        }

        @media (max-width: $media-lg) {
          height: 100px;
        }

        @media (max-width: $media-md) {
          height: 180px;
        }

        @media (max-width: $media-sm) {
          height: 150px;
        }
      }
    }

    &-cta {
      position: relative;
      z-index: 2;
    }

    &-vector-bottom {
      width: 100%;
      position: absolute;
      bottom: 0;
      z-index: 1;
    }
  }

  &__basket {
    padding-top: 50px;
    padding-bottom: 50px;
    position: relative;
    background: #F9F5F1;

    h2 {
      margin-bottom: 20px;

      @media (max-width: $media-md) {
        margin-bottom: 20px;
      }
    }

    &-image {
      padding-right: 100px;

      &.m-left-padding {
        padding-left: 100px;
        padding-right: 0;

        @media (max-width: $media-lg) {
          padding: 0;
          margin-bottom: 30px;
        }
      }

      img {
        @include border-radius();
      }

      @media (max-width: $media-lg) {
        padding: 0;
        margin-bottom: 30px;
      }
    }

    &-content {
      position: relative;
      top: 50%;
      transform: translateY(-50%);

      @media (max-width: $media-sm) {
        top: 0;
        transform: none;
        margin-bottom: 15px;
      }
    }
  }

  &__fourth {
    background: #fff;
    position: relative;
    overflow: hidden;
    margin-bottom: 100px;
    @include border-radius(20px);

    @media (max-width: $media-md) {
      padding: 100px 0;
    }

    &-tomato {
      position: absolute;
      left: 20px;
      top: 100px;
      width: 400px;

      @media (max-width: $media-xl) {
        width: 300px;
      }

      @media (max-width: $media-lg) {
        width: 150px;
        top: 50px;
        left: 20px;
      }

      @media (max-width: $media-md) {
        width: 70px;
        bottom: -5px;
        top: 20px;
      }
    }

    &-mushroom {
      position: absolute;
      right: 50px;
      top: 50px;
      width: 200px;

      @media (max-width: $media-xl) {
        width: 150px;
      }

      @media (max-width: $media-lg) {
        width: 100px;
        right: 40px;
      }

      @media (max-width: $media-md) {
        width: 50px;
        right: 10px;
        top: 130px;
      }
    }

    &-beans {
      position: absolute;
      left: 120px;
      bottom: 200px;
      width: 150px;

      @media (max-width: $media-xl) {
        width: 130px;
        bottom: 250px;
      }

      @media (max-width: $media-lg) {
        width: 80px;
        left: 20px;
      }

      @media (max-width: $media-md) {
        width: 40px;
        left: 20px;
        bottom: 10px;
      }
    }

    &-berries {
      position: absolute;
      left: 320px;
      bottom: 500px;
      width: 100px;

      @media (max-width: $media-xl) {
        width: 80px;
        bottom: 600px;
      }

      @media (max-width: $media-lg) {
        width: 50px;
        left: 60px;
      }

      @media (max-width: $media-md) {
        right: 30px;
        left: auto;
        bottom: 110px;
      }
    }

    &-fish {
      position: absolute;
      left: 220px;
      bottom: 50px;
      width: 400px;

      @media (max-width: $media-xl) {
        width: 300px;
      }

      @media (max-width: $media-lg) {
        width: 200px;
        left: 100px;
        bottom: 30px;
      }

      @media (max-width: $media-md) {
        display: none;
      }
    }

    h2 {
      margin-bottom: 75px;

      @media (max-width: $media-md) {
        margin-bottom: 25px;
      }
    }

    &-block {
      position: relative;
      border-bottom: 1px solid darken($color-gray, 15%);
      padding: 45px 10px;

      &-order {
        width: 65px;
        height: 65px;
        border: 1px dotted $color-dark;
        border-radius: 50%;
        position: absolute;
        top: 65px;
        left: 10px;

        @media (max-width: $media-md) {
          width: 50px;
          height: 50px;
          top: 85px;
          left: 15px;
        }

        &-number {
          position: relative;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          float: left;
          font-size: 20px;
        }
      }

      &-content {
        padding-left: 105px;

        @media (max-width: $media-md) {
          padding-left: 80px;
        }
      }
    }
  }

  &__countdown {
    position: relative;
    margin-bottom: 15px;

    @media (max-width: $media-sm) {
      border-radius: 20px;
      margin-top: 20px;
    }

    &-content {
      position: relative;
      left: 50%;
      background: #E6F1E1;
      border-bottom-right-radius: 20px;
      border-bottom-left-radius: 20px;
      padding: 5px;
      float: left;
      @include transform(translateX(-50%));

      &.m-sidebar {
        border-radius: 20px;
        float: none;
        background: $color-gray-light;
      }

      &.m-sidebar &-left, &.m-sidebar &-right {
        width: 100%;
        float: none;
        font-size: 16px;
        padding: 5px 5px;
      }

      &.m-sidebar &-left {
        padding: 5px 5px 0;
      }

      &.m-highlight {
        background: #FFDDCA;
      }

      @media (max-width: $media-md) {
        border-radius: 20px;
        margin-top: 0;
      }

      &-left {
        padding: 5px 5px;
        text-align: center;
        font-size: 18px;
        width: 58%;
        float: left;
      }

      &-right {
        width: 42%;
        float: left;
        text-align: center;
        padding: 12px 5px 10px;
      }
    }
  }
}
