@mixin background-image( $backgroundSize: cover) {
  -webkit-background-size: $backgroundSize;
  background-size: $backgroundSize;
  background-position: center;
  background-repeat: no-repeat;
}

@mixin default-background-image() {
  background-image: url('./assets/generic-background2.svg');
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
}

@mixin border-radius ($radius: 10px) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;

  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

@mixin transition ($transition) {
  -webkit-transition: $transition;
  -moz-transition:    $transition;
  -ms-transition:     $transition;
  -o-transition:      $transition;
}

@mixin transform($string){
  -webkit-transform: $string;
  -moz-transform: 	 $string;
  -ms-transform: 		 $string;
  -o-transform: 		 $string;
}

@mixin text-ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin vertically-centered() {
  top: 50%;
  @include transform(translateY(-50%));
}

@mixin box-shadow($value: 0 4px 15px -2px #dddddd) {
-webkit-box-shadow: $value;
-moz-box-shadow: $value;
box-shadow: $value;
}