$color-main: #ff864f;
$color-main-darker: #CB6202;
$color-main-new: #FFB545;
$color-main-new-darker: #ffa000;
$color-main-new-bg: #F9F5F1;
$color-main-new-light: #FFE1AF;
$color-main-new-lighter: #FFECCC;
$color-sidebar: #F9F5F1;
$color-new-pink: #FFCECC;
$color-new-green: #74BB88;
$color-dark: #222222;
$color-discount: #FF5555;
$color-error: #ff3e41;
$color-gray: #f2f2f2;
$color-gray-light: #FBFBFB;
$color-muted: #A7A7A7;

// custom breakpoints for fluid-width scaling
$media-xl-2: 1599px;
$media-xl-1: 1399px;
$media-lg-1: 1150px;
$media-sm-1: 720px;

$media-xl: 1199px;
$media-lg: 991px;
$media-md: 767px;
$media-sm: 576px;
$media-xs: 450px;

$container-lg: 1170px;
$container-md: 970px;
$container-sm: 750px;

$text-height: 22px;
$letter-spacing-spread: 2px;