@import 'variables';
@import 'mixins';
@import 'forms';
@import 'navbar';
@import 'marketplaceSidebar';

.first-order-coupon {
  background: $color-main-new-lighter;
  width: 100%;
  padding: 10px 15px;
  text-align: center;

  &.m-with-sidebar {
    padding: 10px 15px 10px $marketplace-sidebar-width;

    @media (max-width: $media-md) {
      padding-left: 15px;
    }
  }

  @media (max-width: $media-md) {
    padding: 10px 15px;
  }
}

.first-order-coupon-small {
  background: $color-main-new-bg;
  padding: 10px 15px;
  text-align: center;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  font-size: 95%;
}