.custom-checkbox *, .custom-checkbox *::before, .custom-checkbox *::after {
  box-sizing: content-box !important;
}

.custom-checkbox input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-checkbox span {
  line-height: 1.54;
  font-size: 1rem;
  font-family: inherit;
}

.custom-checkbox {
  display: table;
  position: relative;
  padding-left: 1.8rem;
  cursor: pointer;
  margin-bottom: .5rem;
  width: 100%;
}

.custom-checkbox input[type="checkbox"] ~ .custom-checkbox-input {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.25rem;
  width: 1.25rem;
  background: rgba(241, 245, 248, 1);
  transition: background 250ms;
  border: 1px solid rgba(184, 194, 204, 1);
  border-radius: 0.125rem;
}

.custom-checkbox input[type="radio"] ~ .custom-checkbox-input {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.25rem;
  width: 1.25rem;
  background: rgba(241, 245, 248, 1);
  transition: background 250ms;
  border: 1px solid rgba(184, 194, 204, 1);
  border-radius: 2.0rem;
}

.custom-checkbox input[type="checkbox"] ~ .custom-checkbox-input::after {
  content: '';
  position: absolute;
  display: none;
  left: .45rem;
  top: .18rem;
  width: .25rem;
  height: .6rem;
  border: solid rgba(255, 255, 255, 1);
  border-width: 0 2px 2px 0;
  transition: background 250ms;
  transform: rotate(45deg);
}

.custom-checkbox input[type="radio"] ~ .custom-checkbox-input::after {
  content: '';
  position: absolute;
  display: none;
  left: .25rem;
  top: .25rem;
  width: .75rem;
  height: .75rem;
  border-radius: 2.0rem;
  background: rgba(255, 255, 255, 1);
  transition: background 250ms;
}

.custom-checkbox input:disabled ~ .custom-checkbox-input::after {
  border-color: rgba(135, 149, 161, 1);
}

.custom-checkbox input:checked ~ .custom-checkbox-input::after {
  display: block;
}

.custom-checkbox:hover input ~ .custom-checkbox-input,
.custom-checkbox input:focus ~ .custom-checkbox-input {
  background: rgb(231, 238, 243);
}

.custom-checkbox input:focus ~ .custom-checkbox-input {
  box-shadow: 0 0 0 2px rgba(52,144,220,0.5);
}

.custom-checkbox input:checked ~ .custom-checkbox-input {
  background: rgba(0, 130, 243, 1);
  border-color: rgba(0, 130, 243, 1);
}

.custom-checkbox input[type="checkbox"]:disabled ~ .custom-checkbox-input {
  background: rgba(241, 245, 248, 1);
  border-color: rgba(184, 194, 204, 1);
  opacity: 0.6;
  cursor: not-allowed;
}

.custom-checkbox input[type="radio"]:disabled ~ .custom-checkbox-input {
  background: rgba(241, 245, 248, 1);
  border-color: rgba(184, 194, 204, 1);
  opacity: 0.6;
  cursor: not-allowed;
}

.custom-checkbox input[type="radio"]:disabled ~ .custom-checkbox-input::after {
  background: rgba(135, 149, 161, 1);
}

.custom-checkbox input:checked:focus ~ .custom-checkbox-input, .custom-checkbox:hover input:not([disabled]):checked ~ .custom-checkbox-input {
  background: rgba(13, 143, 255, 1);
  border-color: rgba(13, 143, 255, 1);
}

.custom-checkbox .custom-checkbox-input::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 3rem;
  height: 3rem;
  margin-left: -0.85rem;
  margin-top: -0.85rem;
  background: rgba(0, 130, 243, 1);
  border-radius: 2rem;
  opacity: .6;
  z-index: 99999;
  transform: scale(0);
}

@keyframes b-ripple {
  0% {
    transform: scale(0);
  }

  20% {
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}

@keyframes b-ripple-duplicate {
  0% {
    transform: scale(0);
  }

  30% {
    transform: scale(1);
  }

  60% {
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}

.custom-checkbox input + .custom-checkbox-input::before {
  animation: b-ripple 250ms ease-out;
}

.custom-checkbox input:checked + .custom-checkbox-input::before {
  animation-name: b-ripple-duplicate;
}

.custom-checkbox .custom-checkbox-input::before {
  visibility: hidden;
}

.custom-checkbox input:focus + .custom-checkbox-input::before {
  visibility: visible;
}

.custom-checkbox:first-child .custom-checkbox-input::before {
  visibility: hidden;
}