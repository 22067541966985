@import 'variables';
@import 'mixins';

$font-size-regular: 16px;
$font-size-small: 87.5%;
$font-size-h1: 45px;
$font-size-h1-xs: 30px;
$font-size-h2: 32px;
$font-size-h2-xs: 27px;
$font-size-h3: 23px;
$font-size-h3-xs: 18px;
$font-size-h4: 18px;
$font-size-h4-xs: 16px;
$font-size-h5: 16px;
$font-size-h5-xs: 16px;
$font-family-serif: 'Salsa', cursive;

body {
  font-size: $font-size-regular;
  font-family: 'Metropolis', sans-serif;
  line-height: 1.4;
  //letter-spacing: 0.2px;
}

h1, h2, h3, h4, h5 {
  margin: 0;
  margin-bottom: 10px;
  font-family: 'Source Serif Pro', sans-serif;
  font-weight: bold;
  color: $color-dark;
}

small {
  font-size: $font-size-small;
}

.text-primary, p.text-primary {
  color: $color-dark !important;
}

h1, .text-h1 {
  font-size: $font-size-h1;

  @media (max-width: $media-md) {
    font-size: $font-size-h1-xs;
  }
}

h2, .text-h2 {
  font-size: $font-size-h2;

  @media (max-width: $media-md) {
    font-size: $font-size-h2-xs;
  }
}

h3, .text-h3, legend {
   font-size: $font-size-h3;

   @media (max-width: $media-md) {
     font-size: $font-size-h3-xs;
   }
}

h4, .text-h4 {
  font-size: $font-size-h4;
  font-weight: 600;
  line-height: 1.3;

  @media (max-width: $media-md) {
    font-size: $font-size-h4-xs;
  }
}

h5, .text-h5 {
  font-size: $font-size-h5;
  font-weight: 600;
  line-height: 1.6;

  @media (max-width: $media-md) {
    font-size: $font-size-h5-xs;
  }
}

a {
  color: $color-dark;

  &:hover, &:focus, &:active {
    text-decoration: none;
    color: $color-dark;
  }

  &.text-primary:hover {
    color: $color-main !important;
  }

  &.link {
    color: $color-dark;
    text-decoration: underline;

    &:hover, &:focus, &:active {
      color: $color-main;
    }
  }
}

.badge {
  font-size: $font-size-small;
  padding: 10px 20px;
  @include border-radius();

  &.badge-light {
    background: $color-main-new-bg;

    &.m-active {
      background: $color-main-new;
    }
  }

  &.badge-info {
    background: $color-main-new-darker;
  }
}

.text-letter-spacing {
  letter-spacing: 2px;
  margin-bottom: 30px;
}

.text-muted {
  color: $color-muted !important;
}

.text-semi-bold {
  font-weight: 600;
}

.text-large {
  font-size: 20px;
}

.text-discount {
  color: $color-discount;
}

.text-info {
  color: #26A0D3 !important;
}

.text-ellipsis {
  @include text-ellipsis();
}