@import 'variables';
@import 'typography';

.ios {

  &__first {
    padding-top: 150px;
    padding-bottom: 205px;
    background: $color-gray;
    position: relative;
    overflow: hidden;

    @media (max-width: $media-lg) {
      padding-top: 160px;
      padding-bottom: 200px;
    }

    @media (max-width: $media-md) {
      padding-top: 120px;
      padding-bottom: 230px;
    }

    &-circle-green {
      position: absolute;
      bottom: 20px;
      width: 330px;
      right: 149px;

      @media (max-width: $media-xl) {
        width: 277px;
        bottom: 2px;
        right: 100px;
      }

      @media (max-width: $media-lg) {
        width: 207px;
        bottom: 2px;
        left: -50px;
        right: auto;
      }

      @media (max-width: $media-md) {
        width: 177px;
        right: -145px;
        bottom: 60px;
      }
    }

    &-circle-purple {
      position: absolute;
      bottom: 262px;
      width: 290px;
      right: -72px;

      @media (max-width: $media-xl) {
        width: 266px;
        bottom: 202px;
      }

      @media (max-width: $media-lg) {
        width: 220px;
        right: -60px;
        bottom: 2px;
      }

      @media (max-width: $media-md) {
        width: 190px;
        right: -60px;
        bottom: 2px;
      }
    }

    &-circle-top-left {
      position: absolute;
      top: 0;
      left: 0;
      width: 455px;

      @media (max-width: $media-lg) {
        width: 355px;
      }

      @media (max-width: $media-md) {
        width: 250px;
        left: -20px;
        top: 50px;
      }
    }

    &-circle-bottom-right {
      position: absolute;
      right: -190px;
      top: -74px;
      width: 880px;

      @media (max-width: $media-xl) {
        width: 750px;
        right: -170px;
        top: 0;
      }

      @media (max-width: $media-lg) {
        display: none;
      }
    }

    &-circle-bottom-left {
      position: absolute;
      bottom: -395px;
      left: 1px;
      width: 400px;

      @media (max-width: $media-lg) {
        width: 420px;
      }

      @media (max-width: $media-md) {
        width: 350px;
        left: -50px;
        bottom: -300px;
      }
    }

    &-content {
      a:hover {
        text-decoration: underline;
      }
    }
  }

  &__form {
    position: relative;
    top: -120px;
  }
}