@import 'variables';
@import 'mixins';
@import 'forms';

.service-area-modal {

  &__choices {
    margin-bottom: 30px;
  }
}
.service-area-choice {
  @include border-radius();
  box-shadow: 0 4px 15px -2px #dddddd;
  padding: 20px;
  color: $color-dark;

  &:hover {
    cursor: pointer;
  }

  &:hover &__title {
    color: $color-main;
  }

  img {
    width: 50px;
    margin-right: 5px;
  }
}