@import 'variables';
@import 'mixins';
@import 'typography';
@import 'navbar';

.search {
  width: 100%;
  position: absolute;
  top: $navbar-height;
  background: white;
  z-index: 3;
  padding-top: 30px;
  overflow: hidden;
  margin-bottom: 60px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  @include box-shadow();

  @media (max-width: $media-lg) {
    top: $navbar-height-xs;
  }

  &__header {
    margin-bottom: 30px;

    @media (max-width: $media-sm) {
      margin-bottom: 10px;
    }
  }

  &__close {
    color: $color-dark;

    svg {
      font-size: 30px;

      @media (max-width: $media-sm) {
        font-size: 22px;
      }
    }

    &:hover {
      cursor: pointer;
      color: $color-main;
    }
  }

  .sidebar-content {
    min-height: 0 !important;
  }

  &__marketplaces {
    position: relative;

    &-marketplace {
      width: 300px;
      padding-right: 30px;
      vertical-align: text-top;
      display: inline-block;

      @media (max-width: $media-sm) {
        width: 200px;
      }

      &-image {
        width: 100%;
        height: 120px;
        @include background-image();
        @include border-radius();

        @media (max-width: $media-sm) {
          height: 80px;
        }
      }
    }
  }
}